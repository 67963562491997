.trainer-slider .slick-slide {
  margin: 0 5px !important; 
}

/* removes large bottom margin, but when page is resized or sits for a while, margin returns... */
.trainer-slider .slick-slider {
  line-height: 0; 
}

.trainer-slider .slick-slider .slick-list {
  height: 45vh;
}

 /* CARD STYLES */
.trainer-card{
  width: 100%;
  aspect-ratio: 1/1.3;
  border-radius: 32px;
  border: 1px solid transparent;
  position: relative;
}

.trainer-card-img {
  width: 100%;
  height: 100%;
  border-radius: 32px;
  object-fit: cover;
}

.trainer-card-title {
  color: var(--color-N000);
  margin-top: 90%;
}


.trainer-card-overlay {
  background: var(--color-gradient);
  border-radius: 32px;
}

.trainer-card-subtitle {
  line-height: 1.2;
}


/* ------- MEDIA QUERIES ------- */
@media screen and (max-width: 480px) {
  .trainer-slider .slick-slider {
    line-height: 1; 
  }
  .trainer-slider .slick-slider .slick-list {
    height: auto;
  }
  .trainer-card {
    width: 70% !important; 
    height: auto;         
 }      
 .trainer-slider .slick-slide {
  margin: 0 -48px !important;  
 }

}