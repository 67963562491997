.navbar {
  background-color: var(--color-N000);
 }

/* .nav-logo {
  height: 90px; 
  width: auto; 
 } */

 .navbar .nav-link {
  color: var(--color-N900);
 }
 
 .navbar .nav-link.active {
  color: var(--color-N900);
 }

 .btn-contact {
  width: max-content;

  padding: 0.75rem 1.2rem;
  background-color: var(--color-primary-500);
  color: var(--color-N000);
  border-radius: 24px;
  border: none;
  cursor: pointer;
  margin: 10px;
}


/* -------- MEDIA QUERIES -------- */
@media (max-width: 480px) {
  .navbar-toggler {
     border: none;
  }
  .navbar-collapse{
    right:0;
    text-align: right;
    z-index: 1000;
  }
  .btn-contact {
    display: none;
  }
 }