.faq-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* .faq-header {
  margin-bottom: -10px;
} */


/* -------- ACCORDION STYLES -------- */
.custom-accordion {
  width:  80%;
  margin:  0 auto; 
}

.accordion-header {
  background-color: var(--color-N000);
  color: var(--color-N700); 
}

.accordion-body{
  color: var(--color-N600);
}

.accordion-button:not(.collapsed) {
  background-color: var(--color-N000); 
  border-color: var(--colo-N000);
}



 /* ------- MEDIA QUERIES ------- */

 @media screen and (max-width: 480px) {
  .custom-accordion{
    width: 100%;
  }
  
}