@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500;600;700&family=Teko:wght@300..700&display=swap');


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* SET THIS FOR MOBILE VIEWS? (messes with sliders though?) */

/* * {
  max-width:100%;
} */

/* body {
  border: red solid 2px;
} */

:root {
  --color-primary-900: #075946;
  --color-primary-700: #115C4B;
  --color-primary-500: #037C5F;
  --color-primary-400: #A9D0C7;
  --color-primary-300: #D9EBE7;
  --color-primary-100: #F0FAF7;

  --color-N900: #00140F;
  --color-N700: #3A4246;
  --color-N600: #5F6E74;
  --color-N500: #95A3A9;
  --color-N300: #E0E5E7;
  --color-N200: #EDF0F2;
  --color-N100: #F6F8FA;
  --color-N000: #FFFFFF;

  --color-error-500: #DA6363;
  --color-error-100: #FFEBEB;

  --color-gradient: linear-gradient(180deg, rgba(3, 130, 99, 0) -25%, rgba(0, 64, 49, 0.9) 92%);

  --body-font-family: "Barlow", sans-serif;
  --header-font-family: "Teko", sans-serif;

  --display-fs: 96px;
  --h1-fs: 72px;
  --h2-fs: 48px;
  --h3-fs: 32px;
  --h4-fs: 24px;
  --h5-fs: 20px;
  --subhead-fs: 20px;
  --body-text-fs: 16px;
  --label-text-fs: 12px;

} 

.highlight {
  color: var(--color-primary-500)
}

.display {
  font-family: var(--header-font-family);
  font-size: 96px;
  font-weight: 700;
  line-height: 94%;
}

.header_1 {
  font-family: var(--header-font-family);
  font-size: var(--h1-fs);
  font-weight: 700;
}

.header_2 {
  font-family: var(--header-font-family);
  font-size: var(--h2-fs);
  font-weight: 700;
}

.header_3 {
  font-family: var(--header-font-family);
  font-size: var(--h3-fs);
  font-weight: 700;
  /* line-height: 100%; */
}

.header_4 {
  font-family: var(--body-font-family);
  font-size: var(--h4-fs);
  font-weight: 700;
}
.header_5 {
  font-family: var(--body-font-family);
  font-size: var(--h5-fs);
  font-weight: 700;
}

.subhead {
  font-family: var(--body-font-family);
  font-size: var(--subhead-fs);
  font-weight: 500;
  line-height: auto;
}

.body_text_bold {
  font-family: var(--body-font-family);
  font-size: var(--body-text-fs);
  line-height: 16px;
  font-weight: 700;
  /* margin: 10px; */
}

.body_text {
  font-family: var(--body-font-family);
  font-size: var(--body-text-fs);
  line-height: 20px;
  font-weight: 500;
  /* margin: 10px; */
}

.label_text {
  font-family: var(--body-font-family);
  font-size: var(--label-text-fs);
  font-weight: 500;
}

.btn_text {
  font-family: var(--body-font-family);
  font-size: var(--body-text-fs);
  line-height: 16px;
  font-weight: 600;
}

/* p {
  font-family: var(--body-font-family);
  text-align: center;
  font-size: 19px;
  line-height: 24px;;
} */

.text_center {
  text-align: center;
}


/* .slick-slider {
  border: 1px solid green;
} */