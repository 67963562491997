div.btn-container{
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  margin: 1.5rem;
}

div.btn-container i{
  display: inline-block;
  position: relative;
  top: -9px;
}

label {
  font-family: var(--body-font);
  font-size:  15px;
  color: #424242;
  font-weight:  700;
}

.btn-user-mode-switch{
  display: inline-block;
  margin:  0px;
  position: relative;
}

.btn-user-mode-switch > label.btn-user-mode-switch-inner{
  margin:  0px;
  width:  800px;
  height:  45px;
  background: var(--color-N000);
  border-radius:  26px;
  border: 1px solid #E0E5E7;
  overflow: hidden;
  position: relative;
  transition: all  0.5s ease;
  display: block;
}

/* This affects the "trainers" side of the outer switch */
.btn-user-mode-switch > label.btn-user-mode-switch-inner:before{
  content: attr(data-on);
  position: absolute;
  top:  10px;
  right:  150px;
  font-family: var(--body-font);
  font-weight: 700;
  font-size: 15px;
}

/* This is the inner green switch */
.btn-user-mode-switch > label.btn-user-mode-switch-inner:after{
  content: attr(data-off);
  width:  400px;
  height:  39px;
  background: var(--color-primary-500);
  color: white;
  border-radius:  26px;
  position: absolute;
  left:  2px;
  top:  2px;
  /* text-align: center; */
  transition: all  0.5s ease;
  /* box-shadow:  0px  0px  6px -2px #111; */
  padding:  7px  0px; /* This affects the text inside the switch */
}

.btn-user-mode-switch input[type="checkbox"]{
  cursor: pointer;
  width:  50px;
  height:  25px;
  opacity:  0;
  position: absolute;
  top:  0px;
  z-index:  1;
  margin:  0px;
}

/* .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner{
  background: #151515;
  color: #fff;
} */

.btn-user-mode-switch input[type="checkbox"]:checked + label.btn-user-mode-switch-inner:after{
  content: attr(data-on);
  left:  396px; /* How far the inner switch slides */
  background: var(--color-N700);
  color: white;
}

.btn-user-mode-switch input[type="checkbox"]:checked + label.btn-user-mode-switch-inner:before{
  content: attr(data-off);
  right: auto;
  left:  150px; /* Text positioning for "students" */
}

.btn-user-mode-switch input[type="checkbox"]:checked + label.btn-user-mode-switch-inner{
  background: var(--color-N000);
  color: #424242;
}

.btn-user-mode-switch input[type="checkbox"]:checked ~ .alert{
  display: block;
}

/* ------- MEDIA QUERIES ------- */
@media screen and (max-width: 480px) {
  label {
    font-size: var(--body-text-fs);
    font-weight: 600;
  }
  .btn-user-mode-switch > label.btn-user-mode-switch-inner{
    width:  300px;
    height:  55px;
  }
  /* This affects the "trainers" side of the outer switch */
  .btn-user-mode-switch > label.btn-user-mode-switch-inner:before{
    font-size: var(--body-text-fs);
    font-weight: 600;
    right:  20px;
    top:  14px;
  }
  /* This is the inner green switch */
  .btn-user-mode-switch > label.btn-user-mode-switch-inner:after{
    width:  150px;
    height:  49px;
    padding: 12px  0px; /* This affects the text inside the switch */
  }
  .btn-user-mode-switch input[type="checkbox"]:checked + label.btn-user-mode-switch-inner:after{
    left:  146px; /* How far the inner switch slides */
  }
  .btn-user-mode-switch input[type="checkbox"]:checked + label.btn-user-mode-switch-inner:before{
    left:  30px; /* Text positioning for "students" */
  }
}