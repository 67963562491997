.testimonials-section {
  background: var(--color-N200);
  padding-top: 2rem;
  padding-bottom: 2rem;
  align-items: center;
  justify-content: center;
}

.quote-icon {
  height: 63px;
}

hr {
  border-top: 3px solid;
}

.centered-hr {
  display: block;
  width: 25%;
  max-width: 50%;
  margin: 0 auto;
}

/* CARD STYLES */

.testimonial-card {
  color: var(--color-N600);
  background-color:var(--color-N200);
  /* border: 2px solid red; */
  border: none;
}

.testimonial-image-container {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 0;
  position: relative;
}

.testimonial-card-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-card-text {
  width: 60%;
  text-align: center;
}

/* SLIDER STYLES */

/* .testimonials-slider {
  border: 2px solid green;

} */
/* .custom-arrow {
  z-index: 1000; 
 } */


 /* ------- MEDIA QUERIES ------- */

@media screen and (max-width: 480px) {
  .testimonials-container {
    max-width: 100%;
    margin: 10px;
  }
  .testimonial-title{
    font-size: var(--h3-fs);
  }
  .quote-icon {
    height: 43px;
  }
  
  .centered-hr {
    width: 100%;
  }
  
  .testimonials-slider{
    width: 95%;
  }
  .testimonial-card-text {
    width: 90%;
  }

  .testimonial-image-container {
    width: 4rem;
    height: 4rem;
  }
}