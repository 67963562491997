.trainers-section {
  position: relative;
  top: -110px;
  display: flex;
  background: var(--color-N100);
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  margin: 0;
}

.trainers-container {
  max-width:100%;
  margin-left: 0;
  margin-right:0;
  /* margin-bottom: 0; doesn't do anything */
}

.trainer-subhead {
  color: var(--color-N700);
  width: 50%;
}

.trainer-text {
  margin-left: 8%;
}



/* ------- MEDIA QUERIES ------- */
@media screen and (max-width: 600px) {
  .trainers-section {
    top: 0px;
  }
  .trainer-subhead{
    width: 100%;
    font-size: var(--body-text-fs);
  }
  .trainer-text {
    margin: auto;
    text-align: center;
  }
  .trainer-title{
    line-height: 1;
    margin-bottom: 10px;
  }
  .trainer-subhead{
    font-size: var(--body-fs);
    line-height: auto;
  }

}