header {
  background: var(--color-N000);
  /* padding-top: 1rem; */
  margin: 2rem auto;
}

.header_container {
  position: relative;
  z-index: 10 !important;
  max-width:100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
}

/* .header-title {
  width: 80%;
  align-items: center;
  margin: auto;
} */

.header-subhead {
  width: 50%;
  margin: auto;
  color: var(--color-N700);
  align-items: center;
}

.header-label {
  color: var(--color-N600)
}

.btn-header {
  width: max-content;
  display: inline-block;
  background-color: var(--color-primary-500);
  color: var(--color-N000);
  padding: 0.75rem 1.2rem;
  border-radius: 24px;
  border: none;
  cursor: pointer;
  transition: var(--transition);
  font-family: var(--body-font);
  font-size: var(--font-button);
  line-height: 24px;;
  margin: 10px;
}


/* ------- DIAGONAL LINE SVGs ------- */
.sm-header-diagonal-container {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 9999;
  text-align: right;
 }
 
 .sm-digonal-svg {
  position: absolute;
  bottom: 0 !important;
  right: 0 !important;
  width: 100%;
 }

 .lg-header-diagonal-container {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: right;                
 }

 .lg-diagonal {
  position: absolute;
  z-index: 0 !important;
  opacity: .5;
  top: 0;
  right: 0;
}


/* ------- CAROUSEL STYLING ------- */

.hero-card {
 /* margin-right: -10; */
 aspect-ratio: 2/1;
 width: 100%;
 height: auto;
 border-radius: 40px;
 border: 1px solid transparent;
 padding: 0;
}

.hero-slider {
  margin-top: 2rem;
  width: 100%;
  height: auto;
}


.slide {
  transform: scale(0.8);
  transition: ease-in-out 8000ms;
  opacity: 0.9;
}

.activeSlide {
  transform: scale(1);
  opacity: 1;
}



/* ------- MEDIA QUERIES ------- */

@media screen and (max-width: 480px) {
  header{
    margin: 8px 0;
  }
  .display{
    font-size: var(--h2-fs);
    font-weight: 700;
    line-height: 100%;
    /* word-wrap: break-word;  */
    /* Ensure long words break onto the next line */
    /* overflow-wrap: break-word; */
     /* Modern alternative to word-wrap */
  }
  .header-subhead {
    font-size: var(--body-text-fs);
    width: 100%;
    margin: auto;
    color: var(--color-N700);
    align-items: center;
  }
  .sm-header-diagonal-container {
    display: none;
 }
  .hero-card{
    border-radius: 20px;
  }
  .hero-slider{
    width:100%;
  }

}

@media (max-width: 1480px) {
  .lg-diagonal {
     display: none; 
   }
 }