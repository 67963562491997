footer {
  background: var(--color-N900);
  padding-bottom: 40px;
}

footer a {
  color: var(--color-N000);
}

.footer-logo {
  height: 53px;
  width: auto;
}
.footer-container {
  color: var(--color-N000);
  padding-top: 40px;
}

.footer-socials {
  color: var(--color-N000);
  display: flex;
  justify-content: left;
  gap: 8px;
  margin-bottom: 4rem;
}

.footer-socials a {
  font-size: 20px;
}

.permalinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto 2rem;
  list-style: none;
}

.permalinks a {
  text-decoration: none;
}
.form-placeholder-text {
  color: var(--color--N500)
}

.form-label-text {
  color: var(--color-N000)
}

.btn-form-footer {
  padding: 0.75rem 6.5rem;
  background-color: var(--color-primary-500);
  color: var(--color-N000);
  border-radius: 1.5rem;
  border: none;
  cursor: pointer;
  transition: var(--transition);
  font-family: var(--body-font);
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  margin: 10px;
}

.footer-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 5px;
 }

 .btn-modal {
  border: none;
  background: none;
  color: var(--color-N000)
 }

/* ============== MEDIA QUERIES ==============  */

@media (max-width: 576px) {
  .permalinks{
    display: none;
  }  
  .footer-container {
    align-items: center !important;
    text-align: center !important;
  }
  .footer-logo{
    justify-content: center !important;
    margin-bottom: 30px;
  }
  .footer-socials {
    justify-content: center;
    margin-bottom: 1rem;
  }
  .footer-socials a {
    font-size: 23px;
  }
  .footer-form{
    justify-content: center !important;
    width: 100%;
    text-align: left;
  }
  .footer-links{
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: var(--body-fs) !important;
    font-weight: 500;
  }
  .btn-form-footer{
    width: 100%;
  }
}