.guide-container {
  align-items: center; 
  margin-top: -4rem; /* Unsure why the top margin is so big, will return to this during review */
  margin-left: auto;
  margin-right: auto;  
  /* padding-top: 2rem; */
  padding-bottom: 2rem;
  max-width: 80%;
  color: var(--color-N900);
}

.num-column-trainee {
  margin-right: -1rem;
}

.num-column-trainer {
  margin-right: -1rem;
}

.mobile{
  display:none;
}

/* START BLOCK STYLING*/

.trainer-start {
  text-align: center;
  background-color: var(--color-N700);
  border-radius: 32px;
  color: var(--color-N000);
  padding: 18px;
}

.trainee-start {
  text-align: center;
  background-color: var(--color-primary-500);
  border-radius: 32px;
  color: var(--color-N000);
  padding: 18px;
}


.btn-start-trainee {
  width: max-content;
  display: inline-block;
  background-color: var(--color-N000);
  color: var(--color-primary-900);
  padding: 0.75rem 1.2rem;
  border-radius: 24px;
  border: none;
  cursor: pointer;
  transition: var(--transition);
  margin-bottom: 15px;
}

.btn-start-trainer {
  width: max-content;
  display: inline-block;
  background-color: var(--color-N000);
  color: var(--color-primary-900);
  padding: 0.75rem 1.2rem;
  border-radius: 24px;
  border: none;
  cursor: pointer;
  transition: var(--transition);
  margin-bottom: 15px;
}

/* VIDEO STYLES & CONTROLS */
.media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 10px 0; */
  
 }

 .guide-image {
 max-width: 100%;
 height:auto;
 }
 
 video {
  max-width: 100%;
  height: auto;
  border-radius: 32px;
 }

 video::-webkit-media-controls {
  display: none;
 }
 
 video::-webkit-media-controls-play-button {
  display: none;
 }
 
 video::-webkit-media-controls-volume-slider {
  display: none;
 }
 
 video::-webkit-media-controls-current-time-display {
  display: none;
 }
 
 video::-webkit-media-controls-time-remaining-display {
  display: none;
 }
 
 video::-webkit-media-controls-timeline {
  display: none;
 }
 
 video::-webkit-media-controls-mute-button {
  display: none;
 }
 
 video::-webkit-media-controls-fullscreen-button {
  display: none;
 }
 
 video::-webkit-media-controls-rewind-button {
  display: none;
 }
 
 video::-webkit-media-controls-return-to-recent-entry-button {
  display: none;
 }
 
 video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
 }
 
 video::-webkit-media-controls-next-track-button {
  display: none;
 }
 
 video::-webkit-media-controls-previous-track-button {
  display: none;
 }
 
 video::-webkit-media-controls-seek-back-button {
  display: none;
 }
 
 video::-webkit-media-controls-seek-forward-button {
  display: none;
 }
 
 video::-webkit-media-controls-panel {
  display: none;
 }

 /* ------- MEDIA QUERIES ------- */

@media screen and (max-width: 480px) {
  .desktop{
    display: none;
  }
  .mobile{
    display: contents;
  }
  .num-column {
    margin-right: -1.5rem;
  }
  .text-container-mobile {
    padding-top: 15px;
  }
  .guide-container{
    max-width: 100%;
    margin-top:auto;
    padding-top: 2rem;
    margin:auto 0 !important;
  }
  .guide-title {
    font-size: var(--h2-fs);
  }
  .guide-subhead {
    font-size: var(--body-font-fs)
  }
  .start-block {
    font-size: var(--h3-fs) !important;
  }
  .trainee-start {
    margin: 40px auto 0;
    padding: 25px 10px 12px;
    border-radius: 28px;
  }
  .trainer-start {
    margin: 40px auto 0;
    padding: 25px 10px 12px;
    border-radius: 28px;
  }
}